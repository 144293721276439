/* .header-container {
  width: calc(100% - 20px);
  height: 50px;
  padding: 0px 10px 0px 10px;
  background-color: #ff0;
}

.logo-desktop {
  display: block;
}
.logo-mobile {
  display: none;
}


@media (max-width: 768px) {
  .logo-desktop {
    display: none;
  }
  .logo-mobile {
    display: block;
  }
}
*/
/* ################################### */
/* ################################### */
/* ################################### */

.header-container {
  /* height: 70px; */
  border-bottom: 1px solid #dadce0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 10px 10px 10px 10px;
  width: calc(100% - 20px);

  /* background: #f3f6f9; */

  margin: 0px;
}

.logo-desktop {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

/* .header-link-button {
  color: #3d464d !important;
  text-transform: none !important;
}
.header-link-button:hover {
  color: #000 !important;
} */

/* ################################### */
/* ################################### */
/* ################################### */

/* .section-desktop {
  display: flex;
}
.section-mobile {
  display: none;
} */

/* ################################### */
/* ################################### */
/* ################################### */

/* @media (max-width: 768px) {
  .header-logo {
    font-size: 0.8rem;
  }

  .section-desktop {
    display: none !important;
  }
  .section-mobile {
    display: flex !important;
  }
} */

/* ################################### */
/* ################################### */
/* ################################### */

.mor-button-1 {
  background: #f20091;
  color: #fff;
  line-height: 100%;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;

  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;

  -webkit-font-smoothing: antialiased;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;

}

.mor-button-1:hover {
  background: #fa5f9b;
  color: #fff;
}

.mor-button-2 {
  background: #f20091;
  color: #fff;
  line-height: 100%;
  padding: 5px 6px 5px 6px;
  border-radius: 5px;

  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;

  -webkit-font-smoothing: antialiased;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

.mor-button-2:hover {
  background: #fa5f9b;
  color: #fff;
}

/* ################################### */
/* ################################### */
/* ################################### */

.white-button-1 {
  background: #fff;
  color: #757575;
  line-height: 100%;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;

  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;

  -webkit-font-smoothing: antialiased;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

.white-button-1:hover {
  background: #e6e6e6;
  color: #333 !important;
}

.white-button-2 {
  background: #fff;
  color: #757575;
  line-height: 100%;
  padding: 5px 6px 5px 6px;
  border-radius: 5px;
  border: 1px solid #ccc;

  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;

  -webkit-font-smoothing: antialiased;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

.white-button-2:hover {
  background: #e6e6e6;
  color: #333 !important;
}

/* ################################### */
/* ################################### */
/* ################################### */

.header-button-color {
  color: #8090b5 !important;
}

.header-button-color:hover {
  color: #fff !important;
  background-color: #1565c0 !important;
}

.menu-item {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  list-style: none !important;
  font-size: 1rem !important;
}

.header-button:hover {
  border-color: #1565c0 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

/* ################################### */
/* ################################### */
/* ################################### */
