.screen-console-container {

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;

  background:rgba(255, 255, 255, 1.0);


  position: fixed;
  z-index: 1500;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}
