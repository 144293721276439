.get-quote-header-container {
  /* border-bottom: 1px solid #cccccc; */
  position: absolute;
  top: 0px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 100;
  max-width: calc(1000px - 30px);
}

.get-quote-final-message-container {
  border: 1px solid #dadce0;
  width: calc(100% - 40px);
  max-width: 400px;
  padding: 40px 20px 40px 20px;
  margin-top: 20px;
  margin-bottom:20px;
}

@media (max-width: 1000px) {
  .get-quote-final-message-container  {
    border: none;
    width: calc(100% - 30px);
    padding: 15px;
  }
}
