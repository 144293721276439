.clinic-container {
  width: calc(100% - 50px);
  padding: 15px 25px 25px 25px;
  max-width: 1000px;
  background: #fff;
  color: #3c4043 !important;
  display: inline-block;
}

.clinic-active-button-border {
  border: 1px solid rgba(19, 96, 239, 0.5) !important;
}

.clinic-active-button-border:hover {
  border: 1px solid #1360ef !important;
}

.clinic-thumbnail{  
  width: 120px;
  height: 120px;
  object-fit: cover;
  cursor:pointer;
  border-radius: 5px;
  border: 1px solid #89AFF7;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}


.clinic-about{
  text-align: justify;
            line-height: 1.8;
            font-size: 1.1rem;
}

.clinic-about-short{

  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
