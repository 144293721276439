.blue-text {
    color: #1360ef !important
}

.badge {
    color: #1360ef !important;
    background: rgba(19, 96, 239, .1) !important;
    padding: 5px 8px !important;
    font-size: .9rem !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
    line-height: 100% !important
}

.text-justify {
    text-align: justify !important
}

@media (min-width:1400px) {
    .feature-item-height {
        height: 349.094px
    }
}

.footer-blue {
    background-color: #1e6add !important;
    color: #fff !important
}

.padding-left-80 {
    padding-left: 80px !important
}

.features-blue {
    color: #fff;
    background: linear-gradient(135deg, #172a74, #21a9af);
    background-color: #184e8e;
    padding-bottom: 30px
}

.features-blue p {
    color: rgba(255, 255, 255, .6)
}

.features-blue h2,
.features-boxed h2 {
    font-weight: 700;
    margin-bottom: 40px;
    padding-top: 40px;
    color: inherit
}

.features-blue .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto 60px
}

.features-blue .item {
    min-height: 100px;
    padding-left: 80px;
    margin-bottom: 40px
}

.features-blue .item .name {
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px;
    color: inherit
}

.features-blue .item .description {
    font-size: 15px;
    margin-bottom: 0
}

.features-blue .item .icon {
    font-size: 40px;
    color: #ffcc1e;
    float: left;
    margin-left: -65px
}

.features-boxed {
    color: #313437;
    background-color: #eef4f7
}

.features-boxed p,
.highlight-clean p {
    color: #7d8285
}

.features-boxed .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto
}

.features-boxed .intro p {
    margin-bottom: 0
}

.features-boxed .features {
    padding: 50px 0
}

.features-boxed .item,
.footer-dark .item.social,
.highlight-blue .buttons,
.highlight-clean .buttons {
    text-align: center
}

.features-boxed .item .box {
    text-align: center;
    padding: 30px;
    background-color: #fff;
    margin-bottom: 30px
}

.features-boxed .item .icon {
    font-size: 60px;
    color: #1485ee;
    margin-top: 20px;
    margin-bottom: 35px
}

.features-boxed .item .name {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: 0;
    color: inherit
}

.features-boxed .item .description {
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 20px
}

.footer-basic {
    padding: 40px 0;
    background-color: #fff;
    color: #4b4c4d
}

.footer-basic ul {
    padding: 0;
    list-style: none;
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 0
}

.footer-basic li {
    padding: 0 10px
}

.footer-basic ul a,
.footer-clean ul a {
    color: inherit;
    text-decoration: none;
    opacity: .8
}

.footer-basic ul a:hover,
.footer-clean ul a:hover,
.highlight-blue .buttons .btn:hover,
.highlight-clean .buttons .btn:hover {
    opacity: 1
}

.footer-basic .social {
    text-align: center;
    padding-bottom: 25px
}

.footer-basic .social>a {
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin: 0 8px;
    color: inherit;
    opacity: .75
}

.footer-basic .social>a:hover,
.footer-clean .item.social>a:hover,
.footer-dark .item.social>a:hover {
    opacity: .9
}

.footer-basic .copyright {
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    color: #aaa;
    margin-bottom: 0
}

.footer-clean {
    padding: 50px 0;
    background-color: #fff;
    color: #4b4c4d
}

.footer-clean h3,
.footer-dark h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 16px
}

.footer-clean ul,
.footer-dark ul {
    padding: 0;
    list-style: none;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 0
}

.footer-clean .item.social {
    text-align: right
}

@media (max-width:768px) {
    .footer-clean .item.social {
        text-align: center
    }
}

.footer-clean .item.social>a {
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin-left: 10px;
    margin-top: 22px;
    color: inherit;
    opacity: .75
}

@media (max-width:991px) {
    .footer-clean .item.social>a {
        margin-top: 40px
    }

    .footer-dark .item.social {
        text-align: center;
        margin-top: 20px
    }
}

.footer-clean .copyright {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 13px;
    opacity: .6
}

.footer-dark {
    padding: 50px 0;
    color: #f0f9ff;
    background-color: #282d32
}

.footer-dark ul a {
    color: inherit;
    text-decoration: none;
    opacity: .6
}

.footer-dark ul a:hover {
    opacity: .8
}

.footer-dark .item.text {
    margin-bottom: 36px
}

@media (max-width:767px) {
    .features-blue {
        padding-bottom: 10px
    }

    .features-blue h2,
    .features-boxed h2 {
        margin-bottom: 25px;
        padding-top: 25px;
        font-size: 24px
    }

    .features-blue .intro {
        margin-bottom: 40px
    }

    .features-blue .item {
        min-height: 0
    }

    .footer-clean .item,
    .footer-dark .item:not(.social) {
        text-align: center;
        padding-bottom: 20px
    }

    .footer-clean .item.social>a {
        margin-top: 10px
    }

    .footer-dark .item.text {
        margin-bottom: 0
    }
}

.footer-dark .item.text p {
    opacity: .6;
    margin-bottom: 0
}

.footer-dark .item.social>a {
    font-size: 20px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, .4);
    margin: 0 8px;
    color: #fff;
    opacity: .75
}

.footer-dark .copyright {
    text-align: center;
    padding-top: 24px;
    opacity: .3;
    font-size: 13px;
    margin-bottom: 0
}

.highlight-blue {
    color: #fff;
    background-color: #1e6add;
    padding: 50px 0
}

.highlight-blue p {
    color: #c4d5ef;
    line-height: 1.5
}

.highlight-blue h2 {
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.5;
    padding-top: 0;
    margin-top: 0;
    color: inherit
}

.highlight-blue .intro,
.highlight-clean .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto 25px
}

.highlight-blue .buttons .btn {
    padding: 16px 32px;
    margin: 6px;
    border: none;
    background: 0 0;
    box-shadow: none;
    text-shadow: none;
    opacity: .9;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: .4px;
    line-height: 1
}

.highlight-blue .buttons .btn:active,
.highlight-clean .buttons .btn:active {
    -webkit-transform: translateY(1px);
            transform: translateY(1px)
}

.highlight-blue .buttons .btn-primary,
.highlight-blue .buttons .btn-primary:active {
    border: 2px solid rgba(255, 255, 255, .7);
    border-radius: 6px;
    color: #ebeff1;
    box-shadow: none;
    text-shadow: none;
    padding: 14px 24px;
    background: 0 0;
    transition: background-color .25s
}

.highlight-clean {
    color: #313437;
    background-color: #fff;
    padding: 50px 0
}

.highlight-clean h2 {
    font-weight: 700;
    margin-bottom: 25px;
    line-height: 1.5;
    padding-top: 0;
    margin-top: 0;
    color: inherit
}

.highlight-clean .buttons .btn {
    padding: 16px 32px;
    margin: 6px;
    border: none;
    background: #ddd;
    box-shadow: none;
    text-shadow: none;
    opacity: .9;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: .4px;
    line-height: 1;
    outline: 0
}

.highlight-clean .buttons .btn-primary {
    background-color: #055ada;
    color: #fff
}