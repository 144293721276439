.swiper-slide {
    /* text-align: center;
    font-size: 18px;
    background: #fff;
   */
    /* Center slide text vertically */
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; */
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper-pagination-bullet {
    /* border: 3px solid #484c7a !important; */
    width: 12px !important;
    height: 12px !important;
    background: #484c7a !important;
    opacity: 1 !important;
  }
  
  .swiper-pagination-bullet-active {
    border: 3px solid #484c7a !important;
    width: 6px !important;
    height: 6px !important;
    background: #7fffd4 !important;
  }

  .swiper-pagination {
    bottom: -5px !important;
}