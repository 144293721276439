.sign-in-container {
  border: 1px solid #dadce0;
  width: calc(100% - 40px);
  max-width: 400px;
  padding: 40px 20px 40px 20px;
  margin-top: 20px;
  margin-bottom:20px;
}

@media (max-width: 1000px) {
  .sign-in-container {
    border: none;
    width: calc(100% - 30px);
    padding: 15px;
  }
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
}

.authentication-logo-div {
  margin-bottom: 20px;
  width: calc(100%);
  line-height: 0%;
  text-align: center;
  /* background-color: #FF0;; */
}

.authentication-message-div {
  color: #002752;
  background-color: #efefef;
  border: 1px solid #e8e8e8;

  padding: 10px;
  margin-top: -20px;
  margin-bottom: 20px;
  line-height: 1.5;
  width: calc(100% - 20px);
  /* max-width: 400px; */
  border-radius: 5px;
}
