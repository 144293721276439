.landing-page-section-title {
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.25;
  /* background-color: aquamarine; */
}


.just-in-bootstrap-studio{
  display: none !important;
}