.bs-clinic-card-container {
  background-color: #fff !important;
  border-radius: 14px;
  cursor: pointer;
  border: 1px solid #dadce0;
  max-width: 350px;
  margin: auto;
  padding: 20px;
}

.bs-clinic-card-container:hover {
  border: 2px solid #484C7A;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.bs-clinic-card-logo {
  max-width: 90%;
  max-height: 70px;
  border-radius: 5px;
}

.bs-clinic-card-about {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

