.social-icon {
  opacity: 0.8 !important;
  cursor: pointer !important;
  font-size: 2.4rem !important;
}

.social-icon:hover {
  opacity: 1 !important;
}

.footer-link {
  opacity: 0.8 !important;
  cursor: pointer !important;
  font-size: 1.2rem !important;
  line-height: 1.8rem !important;
}

.footer-link:hover {
  opacity: 1 !important;
}
