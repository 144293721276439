/* xs */
@media (max-width: 576px) {
  /* font size of the app */
  body,
  html {
    font-size: 15px !important;
  }

  /* left and right padding of the list clinic cards */
  .list-clinic-cards-grid-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  /* bottom margin of the clinic cards */
  .clinic-card-container {
    margin-bottom: 20px !important;
  }

  /* size and padding of the clinic card logo container div */
  .clinic-card-logo-container {
    padding: 10px 15px 10px 15px !important;
    height: 60px !important;
  }

  /* size of the clinic logo */
  .clinic-card-logo {
    max-height: 50px !important;
  }

  .clinic-card-name {
    margin: 20px auto 10px auto;
  }

  .clinic-card-rating-container {
    margin: 10px auto 5px auto;
  }
}

/* sm */
@media (min-width: 576px) and (max-width: 768px) {
  /* font size of the app */
  body,
  html {
    font-size: 16px !important;
  }

  /* left and right padding of the list clinic cards */
  .list-clinic-cards-grid-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  /* size and padding of the clinic card logo container div */
  .clinic-card-logo-container {
    padding: 10px 15px 10px 15px !important;
    height: 60px !important;
  }

  /* size of the clinic logo */
  .clinic-card-logo {
    max-height: 50px !important;
  }

  .clinic-card-name {
    margin: 25px auto 15px auto;
  }

  .clinic-card-rating-container {
    margin: 15px auto 10px auto;
  }
}

/* md */
@media (min-width: 768px) and (max-width: 992px) {
  /* font size of the app */
  body,
  html {
    font-size: 16px !important;
  }

  /* left and right padding of the list clinic cards */
  .list-clinic-cards-grid-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  /* size and padding of the clinic card logo container div */
  .clinic-card-logo-container {
    padding: 10px 15px 10px 15px !important;
    height: 60px !important;
  }

  /* size of the clinic logo */
  .clinic-card-logo {
    max-height: 50px !important;
  }

  .clinic-card-name {
    margin: 25px auto 15px auto;
  }

  .clinic-card-rating-container {
    margin: 15px auto 10px auto;
  }
}

/* lg */
@media (min-width: 992px) and (max-width: 1200px) {
  /* font size of the app */
  body,
  html {
    font-size: 16px !important;
  }

  /* left and right padding of the list clinic cards */
  .list-clinic-cards-grid-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  /* size and padding of the clinic card logo container div */
  .clinic-card-logo-container {
    padding: 15px 15px 15px 15px !important;
    height: 90px !important;
  }

  /* size of the clinic logo */
  .clinic-card-logo {
    max-height: 80px !important;
  }

  .clinic-card-name {
    margin: 25px auto 15px auto;
  }

  .clinic-card-rating-container {
    margin: 15px auto 10px auto;
  }
}

/* xl */
@media (min-width: 1200px) and (max-width: 1400px) {
  /* font size of the app */
  body,
  html {
    font-size: 16px !important;
  }

  /* left and right padding of the list clinic cards */
  .list-clinic-cards-grid-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  /* size and padding of the clinic card logo container div */
  .clinic-card-logo-container {
    padding: 15px 15px 15px 15px !important;
    height: 90px !important;
  }

  /* size of the clinic logo */
  .clinic-card-logo {
    max-height: 80px !important;
  }

  .clinic-card-name {
    margin: 25px auto 15px auto;
  }

  .clinic-card-rating-container {
    margin: 15px auto 10px auto;
  }
}

/* xxl */
@media (min-width: 1400px) {
  /* font size of the app */
  body,
  html {
    font-size: 16px !important;
  }

  /* left and right padding of the list clinic cards */
  .list-clinic-cards-grid-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  /* size and padding of the clinic card logo container div */
  .clinic-card-logo-container {
    padding: 15px 15px 15px 15px !important;
    height: 90px !important;
  }

  /* size of the clinic logo */
  .clinic-card-logo {
    max-height: 80px !important;
  }

  .clinic-card-name {
    margin: 25px auto 15px auto;
  }

  .clinic-card-rating-container {
    margin: 15px auto 10px auto;
  }
}
