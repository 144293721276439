.review-color-codes-tooltip-container {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  max-width: 50%;
  background-color: rgba(255, 255, 255, 1);
  display: inline-block;
  padding: 35px 45px 15px 25px;
  border-radius: 5px;
  cursor: default;
  line-height: 1.3;
  z-index: 1;

  /* box-shadow: 0 0px 5px 0 rgb(0 0 0 / 40%) !important; */
  background-color: #f7f7f7;
  border: 1px solid #e5e5e5;
}

.review-color-codes-tooltip-container:hover {
  border: 1px solid #125799;
}

.review-color-codes-legend {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
}
