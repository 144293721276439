.clinic-bootstrap-studio-google-review-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

    text-align: left;
/* 
    display: block;
overflow: auto; */
  }

  
  