.bs-clinic-max-width {
  max-width: 1200px !important;
}

.bs-clinic-social-link-active {
  background: #7FFFD4;
  padding: 10px;
  border-radius: 50px;
  border: 3px solid #484C7A;
  cursor: pointer;
}

.bs-clinic-social-link-passive {
  background: #F7F7F8;
  border: 3px solid #CECECE;
  color: #CECECE;
  cursor: default;
}

.bs-clinic-section {
  padding-top: 60px;
  padding-bottom: 60px;
  /*margin-bottom: 30px;*/
}

.bs-clinic-section-dark {
  background-color: #F7F7F8;
}

.bs-clinic-about-short {
  text-align: justify;
  /*https: //stackoverflow.com/questions/33058004/applying-an-ellipsis-to-multiline-text;*/
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.bs-clinic-services-item {
  background: #FBFBFB;
  padding: 10px;
  border-radius: 5px;
}

.bs-clinic-map-container {
  padding: 10px;
  width: 100%;
  border: 1px solid #484c7a;
  border-radius: 14px;
  margin-left: auto;
  margin-right: auto;
}

.bs-clinic-map-image {
  border-radius: 14px;
  width: 100%;
}

