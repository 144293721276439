.bs-landing-section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (max-width: 900px) {
  .bs-landing-section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.bs-landing-section-title-spacing {
  width: 100%;
  height: 80px;
  /*background-color: #007bff;*/
}

@media (max-width: 900px) {
  .bs-landing-section-title-spacing {
    width: 100%;
    height: 50px;
    /*background-color: #007bff;*/
  }
}

.bs-landing-section-inner-title-spacing {
  width: 100%;
  height: 30px;
  /*background-color: var(--bs-cyan);*/
}

.bs-landing-section-content-spacing {
  width: 100%;
  height: 80px;
  /*background-color: chartreuse;*/
}

@media (max-width: 900px) {
  .bs-landing-section-content-spacing {
    width: 100%;
    height: 50px;
    /*background-color: chartreuse;*/
  }
}

.bs-landing-blue-text {
  color: rgb(0, 123, 255);
}

.bs-landing-max-width {
  max-width: 1200px !important;
}

.bs-landing-item-box {
  padding: 40px 20px;
  background: #F7F7F8;
  border-radius: 14px;
}

.bs-landing-image {
  width: 100%;
  max-width: 400px;
  border-radius: 14px;
}

.bs-landing-link-button {
  color: #0d6efd !important;
}

