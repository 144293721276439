@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");


.hide{
  display:none !important;
}

.yellow{
  background: #FF0 !important;
}
.fullWidth{
  width:100%  !important;
}


/* DONT FORGET !!! */
/* in the index.js >>> typography.allVariants.color also has been set */

:root {
  --text-color: #484c7a !important;
  --header-color: #23263b !important;
  --link-color: #5468ff !important;

  /* clinic sayfasindaki nav-bar icin bunlari eklemem gerekti */
  /* bir sekilde bu variableri bootstrap.scoped.css icinden okuyamiyor veya override oluyorlar. */
  --bs-primary-rgb:13,110,253;
  --bs-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));

}
/* :root {
  --text-color: #F00;
  --header-color: #00F;
  --link-color: #5468ff;
} */


body,
html {
  padding: 0px;
  margin: 0px;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  /* from coolor.co */
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
  font-style: normal;
  font-size: 18px;
  color: rgb(40, 59, 123);
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  /* font-family: Inter, sans-serif !important; */
  /* font-family: "Courier New" !important; */
  /* font-family: Poppins !important; */
  /* font-family: Roboto, Verdana, Arial, sans-serif !important; */
  /* font-stretch: ultra-expanded !important; */
  font-family: "Poppins", Arial, sans-serif !important;
  font-weight: 400 !important;
  color: #484c7a !important;
  color: var(--text-color) !important;
  overflow: hidden;
}


/* ######################## */
/* ######################## */
/* ######################## */
/* for global formating */

.font-family {
  font-family: "Poppins", Arial, sans-serif !important;
}

.text-color {
  color: #484c7a !important;
  color: var(--text-color) !important;
}

.header-color {
  color: #23263b !important;
  color: var(--header-color) !important;
}

.regular-text{
  font-family: "Poppins", Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem;
  color: #484c7a !important;
  color: var(--text-color) !important;
}

.title-3 {
  font-family: "Poppins", Arial, sans-serif !important;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #23263b !important;
  color: var(--header-color) !important;
}

.link-color {
  text-decoration: none !important;
  cursor: pointer !important;
  color: #5468ff !important;
  color: var(--link-color) !important;
}

/* ######################## */
/* ######################## */
/* ######################## */

h1 {
  line-height: 110%;
}

/* h1 {
  font-size: 2.25rem !important;
  line-height: 1.2222222222222223 !important;
  letter-spacing: 0.2px !important;
  font-weight: 800 !important;
  margin: 10px 0 !important;
}

h2 {
  font-size: 1.5rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.1px !important;
  font-weight: 700 !important;
  margin: 40px 0 8px !important;
}

h3 {
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0px !important;
  font-weight: 500 !important;
  margin: 28px 0 8px !important;
} */
/* .MuiSelect-select {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
} */
/* bunu yapmazsan, checkbox lar 100% oluyor ve uzerine tiklamasanda aktive oluyor */
/* nedeni: <FormControlLabel> flex:column ve icindeki tum itemlari, stretch yapiyor */
/* bakiniz: Step2.js -> haveOtherIllnesses */
/* bu duzelttigim css class'i checkbox i tasiyan  */
.MuiFormControlLabel-root {
  align-self: flex-start !important;
}

.outerContainer {
  width: 100%;
  text-align: center;
  /* overflow-x: hidden; */
  /* background: #ff0000; */
}

.innerContainer {
  display: inline-block;
  margin: 10px;
  max-width: 1000px;
  /* box-shadow: 0 0 60px -40px #aaa;*/
  padding: 0px 10px 0px 10px;
  /* overflow-x: hidden; */
  /* background: #dcdcdc; */
}

/* link */
.link,
.link:visited,
.link:active {
  color: #0000ff;
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  font-style: underline;
}

/* button */
.button,
.button:visited,
.button:active {
  color: #0000ff;
  cursor: pointer;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #0000ff;
  border-radius: 5px;
  text-align: center;
}

.button:hover {
  color: #f00;
}

.disabled {
  color: #ccc !important;
  cursor: default;
  border: 1px solid #ccc;
  background-color: rgba(0, 0, 0, 0) !important;;
}

/* flex */
.flex-row-start {
  display: flex !important;
  flex-direction: row !important;
  /* flex-wrap: nowrap !important; */
  justify-content: flex-start !important;
  align-content: stretch !important;
  align-items: center !important;
}

.flex-row-center {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-content: stretch !important;
  align-items: center !important;
}

.flex-row-space-between {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: space-between !important;
  align-content: stretch !important;
  align-items: center !important;
}

.flex-row-end {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: flex-end !important;
  align-content: stretch !important;
  align-items: center !important;
}

.flex-row-start-top {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: flex-start !important;
  align-content: flex-start !important;
  align-items: flex-start !important;
}

.flex-column-center {
  display: flex !important;
  flex-direction: column !important;
  /* flex-wrap: nowrap !important; */
  justify-content: center !important;
  /* align-content: stretch !important; */
  align-items: center !important;
}

.flex-column-start {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  justify-content: flex-start !important;
  align-content: stretch !important;
  align-items: center !important;
}

.flex-column-start-left {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  justify-content: flex-start !important;
  align-content: stretch !important;
  align-items: flex-start !important;
}

.flex-column-center-left {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-content: stretch !important;
  align-items: flex-start !important;
}

.flex-column-space-between {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  justify-content: space-between !important;
  align-content: stretch !important;
  align-items: stretch !important;
}

.flex-column-start-right {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-content: stretch !important;
  align-items: flex-end !important;
}

.flex-grow {
  flex: 1 1 auto;
}

.width-with-padding-0 {
  width: calc(100% - 0px);
  padding: 0px;
}


.width-with-padding-10 {
  width: calc(100% - 20px);
  padding: 10px;
}

.width-with-padding-15 {
  width: calc(100% - 30px) !important;
  padding: 15px;
}

.width-with-padding-20 {
  width: calc(100% - 40px);
  padding: 20px;
}

.width-with-padding-40 {
  width: calc(100% - 80px);
  padding: 40px;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.text-align-center {
  text-align: center;
}

.line-10 {
  padding: 0px;
  margin: 10px 0px 10px 0px;
  border-top: 1px solid #cccccc;
  border-bottom: none;
  width: 99%;
}

.line-15 {
  padding: 0px;
  margin: 15px 0px 15px 0px;
  border-top: 1px solid #cccccc;
  border-bottom: none;
  width: 99%;
}

.line-20 {
  padding: 0px;
  margin: 20px 0px 20px 0px;
  border-top: 1px solid #cccccc;
  border-bottom: none;
  width: 99%;
}

.line-25 {
  padding: 0px;
  margin: 25px 0px 25px 0px;
  border-top: 1px solid #cccccc;
  border-bottom: none;
  width: 99%;
}

.line-30 {
  padding: 0px;
  margin: 30px 0px 30px 0px;
  border-top: 1px solid #cccccc;
  border-bottom: none;
  width: 99%;
}

.bold {
  font-weight: bold;
}

.divider-width-5 {
  height: 1px;
  width: 5px;
}

.divider-width-10 {
  height: 1px;
  width: 10px;
}

.divider-width-15 {
  height: 1px;
  width: 15px;
}

.divider-width-20 {
  height: 1px;
  width: 20px;
}

.divider-width-25 {
  height: 1px;
  width: 25px;
}

.divider-width-30 {
  height: 1px;
  width: 30px;
}

.divider-width-50 {
  height: 1px;
  width: 50px;
}

.divider-height-5 {
  width: 1px;
  height: 5px;
}

.divider-height-10 {
  width: 1px;
  height: 10px;
}

.divider-height-15 {
  width: 1px;
  height: 15px;
}

.divider-height-20 {
  width: 1px;
  height: 20px;
}

.divider-height-25 {
  width: 1px;
  height: 25px;
}

.divider-height-30 {
  width: 1px;
  height: 30px;
}

.divider-height-60 {
  width: 1px;
  height: 60px;
}

.divider-height-100 {
  width: 1px;
  height: 100px;
}

.divider-height-90 {
  width: 1px;
  height: 90px;
}

.divider-height-140 {
  width: 1px;
  height: 140px;
}

.divider-line-width-5 {
  width: 1px;
  height: 20px;
  margin-left:5px;
  margin-right: 5px;
  background-color: #CCCCCC;
}

.phoneTextBox {
  width: calc(100% - 140px);
}

/* for PhoneInput */
.react-tel-input input[type="tel"] {
  height: 56px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1rem;
  width: calc(100% - 0px);
}

.react-tel-input .selected-flag {
  width: 50px !important;
  padding-left: 10px !important;
}

.react-tel-input .selected-flag .arrow {
  left: 24px !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-top: 6px solid #555 !important;
  margin-top: -3px !important;
}

.react-tel-input .selected-flag .arrow.up {
  border-bottom: none !important;
}

.btn-primary {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-family: "Poppins", sans-serif;
  font-style: normal;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  touch-action: manipulation;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin-right: 0.25rem !important;
  /* transition: all 0.5s ease-in-out 0s; */
  transition: all 0.2s ease-in-out 0s;
  font-weight: 500;
  padding: 0.75rem 1rem;
  background-color: rgb(30, 106, 221) !important;
  border-color: rgb(30, 106, 221);
  /* box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.15) !important; */
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important; */
  cursor: pointer;
  color: #fff !important;
  background-color: #1360ef !important;
  border-color: #1360ef !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #1565c0 !important;
  border-color: #1565c0 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.btn-primary-orange{
  
  background-color: #EA8F35 !important;
  border-color: #EA8F35 !important;
}
.btn-primary-orange:hover{
  
  background-color: #DC610B !important;
  border-color: #DC610B !important;
}

.btn-secondary {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-family: "Poppins", sans-serif;
  font-style: normal;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  touch-action: manipulation;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  /* transition: all 0.5s ease-in-out 0s; */
  transition: all 0.2s ease-in-out 0s;
  font-weight: 500;
  padding: 0.75rem 1rem;
  cursor: pointer;
  color: #1360ef !important;
  border-color: #1360ef !important;
  background-color: #fff !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #1565c0 !important;
  border-color: #1565c0 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.btn-primary-disabled,
.btn-primary-disabled:hover,
.btn-secondary-disabled,
.btn-secondary-disabled:hover {
  cursor: default !important;
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  border: 0 !important;
}

.btn-primary-small,
.btn-primary-small:hover{
  padding:5px 5px 4px 5px;
  font-size: 0.8rem;
  line-height: 100%;
}

.text-primary {
  color: #1360ef !important;
}

.no-padding-left {
  padding-left: none !important;
}

.MuiDialogContent-root {
  color: inherit !important;
}

.alert-ok {
  background-color: #d4edda;
  color: #155724;
}

.alert-not-ok {
  background-color: #f8d7da;
  color: #721c24;
}


/* dialog background color */
.MuiBackdrop-root {
  background-color: rgba(25,32,45,.7) !important;
}
