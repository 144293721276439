.clinic-card-container {
  background-color: #fff !important;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 10px;
  margin: 10px;
  width: calc(100% - 20px) !important;
  /* border: 1px solid #f5f5f5; */
  border: 1px solid #dadce0;
  /* max-width: 400px !important; */
}

.clinic-card-container:hover {
  border: 1px solid #4d4d4d;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.clinic-card-logo-container {
  width: calc(100% - 30px) !important;
  background: #f8f8f8;
  border-radius: 5px !important;
}

.clinic-card-logo {
  max-width: 80% !important;
  border-radius: 5px !important;
}

.clinic-card-name {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
}

.clinic-card-about {
  font-size: 1rem;
  text-align: center;
  width: calc(100% - 20px);
  padding: 0px 10px 0px 10px;
  margin: 0px;
  line-height: 1.6rem;

  /* https://stackoverflow.com/questions/33058004/applying-an-ellipsis-to-multiline-text */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.clinic-card-rating-container {
  width: calc(100%);
  padding: 0px 0px 0px 0px;
  /* border-bottom: 1px solid #e0e0e0; */
  /* background-color: #ff0; */
}

.clinic-card-rating-value {
  font-size: 1.1rem;
  margin-right: 10px;
}
.clinic-card-count {
  font-size: 1.1rem;
  margin-left: 10px;
}

.clinic-card-google-review-days-ago-chip {
  display: inline-block;
  padding: 8px 10px 8px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  text-align: center;
  /* max-width: 80px; */
  line-height: 1.15;
}

.clinic-card-chip-green {
  /* background-color: #00cc60; */
  background-color: #d8ffc2;
  /* color: #fff; */
}
.clinic-card-chip-yellow {
  /* background-color: #f1d302; */
  background-color: #fffa99;
}
.clinic-card-chip-orange {
  /* background-color: #f6591b; */
  background-color: #ffe699;
  /* color: #fff; */
}
.clinic-card-chip-red {
  /* background-color: #d31146; */
  background-color: #ffa2a1;
  /* color: #fff; */
}
.clinic-card-chip-white {
  /* background-color: #d31146; */
  background-color: #FFF;
  /* color: #fff; */
}
